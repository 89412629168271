import { NavLink } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="min-h-full pt-16 pb-12 flex flex-col bg-white mt-20">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <a href="/" className="inline-flex">
            <span className="sr-only">Workflow</span>
          </a>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold text-[#069e20] uppercase tracking-wide font-montserrat">
              Errore 404
            </p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl font-montserrat">
              Pagina non trovata
            </h1>
            <p className="mt-3 text-base text-gray-500 font-montserrat">
              Spiacente, non è possibile trovare la pagina che stai cercando.
            </p>
            <div className="mt-6">
              <NavLink
                to="/"
                className="text-base font-medium text-[#069e20] hover:text-[#069e20] font-montserrat"
              >
                Torna indietro<span aria-hidden="true"> &rarr;</span>
              </NavLink>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NotFoundPage;
