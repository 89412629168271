import { useLocation, useParams } from "react-router-dom";

const Details = () => {
  const location = useLocation();
  return (
    <div className="font-montserrat p-4">
      <h1 className="text-4xl">{location.state.title}</h1>
      <p>{location.state.description}</p>
    </div>
  );
};

const WrappedDetails = () => {
  const params = useParams();
  return <Details params={params} />;
};

export default WrappedDetails;
