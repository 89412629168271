import Header from "./Header";
import CategorySelector from "./CategorySelector";
import Footer from "./Footer";
import GridCards from "./GridCards";

const Home = () => {
  return (
    <div className="home">
      <Header title="Tutte le opportunità in un unico posto" />
      <CategorySelector />
      <GridCards />
      <Footer />
    </div>
  );
};

export default Home;
