const Header = ({ title }) => {
  return (
    <div className="banner-image font-montserrat justify-center w-full bg-cover h-64 flex display bg-clip-border bg-red-400">
      <h2 className="flex display justify-center items-center pr-8 pl-8 pb-0 font-montserrat font-bold text-white text-3xl text-center">
        {title}
      </h2>
    </div>
  );
};

export default Header;
