import { useState } from "react";
import classNames from "classnames";

const categories = [
  "Tutte",
  "Informatica",
  "Startup",
  "Saggistica",
  "Video",
  "Arte & Design",
  "Musica",
  "Idee",
  "Sport",
  "Finanza",
  "Impresa",
  "Associazioni",
];

const CategorySelector = () => {
  const [activeFilter, setActiveFilter] = useState(categories[0]);

  const handleClickOnCategory = (event) => {
    console.log(event.target.id);
    setActiveFilter(event.target.id);
  };

  return (
    <div className="justify-center text-center font-montserrat mt-6">
      {/* <h1 className="text-lg font-bold text-4xl mb-3 text-black">
        Trova la tua challenge
      </h1> */}
      <div>
        {categories.map((category) => (
          <button
            className={classNames(
              "p-2 mx-2 my-1 rounded-lg  text-gray-500 hover:text-[#4CAF50]",
              {
                "text-[#4CAF50]": category == activeFilter,
                "bg-[#F3F3F4]": category == activeFilter,
                "font-bold": category == activeFilter,
              }
            )}
            id={category}
            key={category}
            onClick={handleClickOnCategory}
          >
            {category}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CategorySelector;
