import Card from "./Card";

const description =
  "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium ni...";
const cardValues = [
  {
    id: 0,
    title: "Human Knowledge Lab",
    organizer: "Eni",
    description,
    tags: ["idee", "startup", "informatica"],
    imageUrl: "https://i.postimg.cc/Zq68cZ36/Group-18.png",
  },
  {
    id: 1,
    title: "Amazon Storyteller 2022",
    organizer: "Amazon Media EU",
    description,
    tags: ["idee", "saggistica"],
    imageUrl: "https://i.postimg.cc/Zq68cZ36/Group-18.png",
  },
  {
    id: 2,
    title: "Premio Illustratori Tarantasio 2022",
    organizer: "Città di Paullo",
    description,
    tags: ["arte & design"],
    imageUrl: "https://i.postimg.cc/Zq68cZ36/Group-18.png",
  },
  {
    id: 3,
    title: "Vespa Video Art 2022",
    organizer: "Vespa Club d’Italia.",
    description,
    tags: ["idee", "startup", "informatica"],
    imageUrl: "https://i.postimg.cc/Zq68cZ36/Group-18.png",
  },
  {
    id: 4,
    title: "Prisma Art Prize 2022",
    organizer: "Associazione Culturale II Varco",
    description,
    tags: ["idee", "startup", "informatica"],
    imageUrl: "https://i.postimg.cc/Zq68cZ36/Group-18.png",
  },
  {
    id: 5,
    title: "Amazon Storyteller 2022",
    organizer: "Amazon Media EU",
    description,
    tags: ["idee", "saggistica"],
    imageUrl: "https://i.postimg.cc/Zq68cZ36/Group-18.png",
  },
];

const GridCards = () => {
  return (
    <div className="p-12 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:mr-8 md:ml-8 lg:mr-8 lg:ml-8 sm:mr-1 sm:ml-1">
      {cardValues.map((cardInfo) => (
        <Card
          key={cardInfo.id}
          id={cardInfo.id}
          title={cardInfo.title}
          description={cardInfo.description}
          tags={cardInfo.tags}
          image={cardInfo.imageUrl}
          className="p-4"
        />
      ))}
    </div>
  );
};

export default GridCards;
