const Footer = () => {
  return (
    <div>
      <footer className="mt-10 p-4 bg-white shadow md:flex md:items-center md:justify-between md:p-6 bg-[#f9f9f9]">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2022{" "}
          <a href="/" className="hover:underline">
            Challengee™
          </a>
          . All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <a href="/chi-siamo" className="mr-4 hover:underline md:mr-6 ">
              Chi siamo
            </a>
          </li>
          <li>
            <a href="/" className="mr-4 hover:underline md:mr-6">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="/" className="mr-4 hover:underline md:mr-6">
              Licenza
            </a>
          </li>
          <li>
            <a href="/contattaci" className="hover:underline">
              Contattaci
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Footer;
