import React from "react";
import { render } from "react-dom";
import Home from "./Home.js";
import { StrictMode } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CustomNavBar from "./CustomNavBar";
import Details from "./Details.js";
import NotFoundPage from "./NotFoundPage.js";
import WorkInProgress from "./WorkInProgress.js";
import Contact from "./Contact.js";
import Team from "./Team.js";

const App = () => {
  return (
    <StrictMode>
      <BrowserRouter>
        <CustomNavBar />
        <Routes>
          <Route path="/details/:id" element={<Details />} />
          <Route path="/*" element={<NotFoundPage />} />
          <Route path="/contattaci" element={<Contact />} />
          <Route path="/segnala" element={<WorkInProgress />} />
          <Route path="/chi-siamo" element={<Team />} />
          <Route path="/partners" element={<WorkInProgress />} />
          <Route path="/scuole" element={<WorkInProgress />} />

          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </StrictMode>
  );
};

render(<App />, document.getElementById("root"));
