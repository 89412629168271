import { Link } from "react-router-dom";

const Card = ({ id, title, description, image, tags }) => {
  return (
    <Link to={`/details/${id}`} state={{ id, title, description, image, tags }}>
      <div className="card mt-0 rounded-[12px] overflow-hidden shadow-lg font-montserrat">
        <img className="w-full" src={image} alt="" />
        <div className="px-6 py-4">
          <div className="font-bold text-l mb-2">{title}</div>
          <div className="font-semibold text-l mb-2">Eni</div>
          <p className="text-gray-700 text-sm">{description}</p>
        </div>
        <div className="mx-4 mb-2">
          {tags.map((tag) => (
            <span
              key={tag}
              className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
            >
              #{tag}
            </span>
          ))}
        </div>
      </div>
    </Link>
  );
};

export default Card;
