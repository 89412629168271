import { Fragment } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

const navigation = [
  { name: "Dashboard", href: "/", current: true },
  { name: "Segnala opportnunità", href: "/segnala", current: false },
  { name: "Chi siamo", href: "/chi-siamo", current: false },
  { name: "Contattaci", href: "/contattaci", current: false },
];
const userNavigation = [
  { name: "Your Profile", href: "/" },
  { name: "Settings", href: "/" },
  { name: "Sign out", href: "/" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CustomNavBar() {
  return (
    <>
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? "fixed inset-0 z-40 overflow-y-auto" : "",
            "bg-white md:static md:overflow-y-visible"
          )
        }
      >
        {({ open }) => (
          <>
            <div className="justify-center flex display my-4">
              <div className="navbar mb justify-between relative flex display items-center">
                <div className="flex display">
                  <a href="/">
                    <h1 className="font-bold font-montserrat text-4xl">
                      Challengee
                    </h1>
                  </a>
                  <h3 className="font-bold font-montserrat text-md text-[#38BA40]">
                    Beta
                  </h3>
                </div>
                {/* <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0 min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6 " /> */}
                <div className="flex items-center sm:absolute sm:right-0 sm:inset-y-0 md:hidden">
                  <Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-black-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Popover.Button>
                </div>
                <div className="hidden md:flex md:items-center md:justify-end xl:col-span-4">
                  {/* Profile dropdown */}
                  <Menu as="div" className="flex-shrink-0 relative ml-5">
                    {({ open }) => (
                      <>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute z-10 right-0 mt-4 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none"
                          >
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block py-2 px-4 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                  <a
                    href="/contattaci"
                    className="font-bold font-montserrat text-base hover:underline mr-4"
                  >
                    Contattaci
                  </a>
                  <a
                    href="/chi-siamo"
                    className="font-bold font-montserrat text-base hover:underline"
                  >
                    Chi siamo
                  </a>
                  <a
                    href="/segnala"
                    className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-montserrat font-bold rounded-[10] shadow-sm text-white bg-[#38BA40] hover:bg-[#069e20] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Segnala opportunità
                  </a>
                </div>
              </div>
            </div>

            <Popover.Panel as="nav" className="md:hidden" aria-label="Global">
              <div className="panel mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    aria-current={item.current ? "page" : undefined}
                    className={classNames(
                      item.current
                        ? "bg-gray-100 text-gray-900"
                        : "hover:bg-gray-50",
                      "block rounded-md py-2 px-3 text-base font-medium"
                    )}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </>
  );
}
